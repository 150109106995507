body {
    background: #1a1e23;
    margin: 0;
    // background-color: papayawhip; 
    margin: 0 auto;
  }

 p {
    font-family: 'Courier New', Courier, monospace;
    color:white;
  }

  h1{
    font-family: 'Courier New', Courier, monospace;
    color:white;
  }

  h2 {
    font-family: 'Courier New', Courier, monospace;
    color:white;
  }

  h3 {
    font-family: 'Courier New', Courier, monospace;
    color:white;
  }

  h4 {
    font-family: 'Courier New', Courier, monospace;
    color:white;
  }
  
  $bubble-count: 50;
  $sway-type: "sway-left-to-right", "sway-right-to-left";
  
  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }
  
  @function sample($list) {
    @return nth($list, random(length($list)));
  }

  div.tab-content{
    height:80%;
    overflow: auto;
    color:white;
  }

  .nav-tabs .nav-link.active{
    color:black;
    mix-blend-mode: screen;
    background: rgba(255, 255, 255, 0.8);
  }

  .nav-link{
    color:hsla(183, 94%, 76%, .75);
  }

  .nav-tabs{
    font-size: 3vh;
  }

  .btn-outline-info{
    --bs-btn-color: hsla(183, 94%, 76%, 0.9);
    --bs-btn-border-color:hsla(183, 94%, 76%, 0.9);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: hsla(183, 94%, 76%, 0.9);
    --bs-btn-hover-border-color: hsla(183, 94%, 76%, 0.9);
    --bs-btn-focus-shadow-rgb: 13,202,240;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: hsla(183, 94%, 76%, 0.9);
    --bs-btn-active-border-color: hsla(183, 94%, 76%, 0.9);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: hsla(183, 94%, 76%, 0.9);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: hsla(183, 94%, 76%, 0.9);
    --bs-gradient: none;
  }

  .btn-outline-light{
    --bs-btn-bg: hsla(183, 94%, 76%, 0.4);
    --bs-border-width: 5px;
  }

  .nav-link:hover{
    color:white;
  }

  .bubbles {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .bubble {
    position: absolute;
    left: var(--bubble-left-offset);
    bottom: 0%;
    display: block;
    width: var(--bubble-radius);
    height: var(--bubble-radius);
    border-radius: 50%;
    animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;
  
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: hsla(183, 94%, 76%, 0.3);
      border-radius: inherit;
      animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
    }
  
    @for $i from 0 through $bubble-count {
      &:nth-child(#{$i}) {
        --bubble-left-offset: #{random_range(0vw, 100vw)};
        --bubble-radius: #{random_range(1vw, 10vw)};
        --bubble-float-duration: #{random_range(6s, 12s)};
        --bubble-sway-duration: #{random_range(4s, 6s)};
        --bubble-float-delay: #{random_range(0s, 4s)};
        --bubble-sway-delay: #{random_range(0s, 4s)};
        --bubble-sway-type: #{sample($sway-type)};
      }
    }
  }
  
  @keyframes float-up {
    to {
      transform: translateY(-450vh);
    }
  }
  
  @keyframes sway-left-to-right {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(100%);
    }
  }
  
  @keyframes sway-right-to-left {
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(-100%);
    }
  }